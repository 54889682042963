<template>
	<div>
		<h1>This is an about page</h1>
	</div>
</template>

<style scoped>

</style>

<script>


export default {

	data() {
		return {
			user: '',
			address: '',
		}
	},

	computed: {

	},

	methods: {

	},



}
</script>


